import { ieltsHost, ieltsWeb } from '../env';
import { logError } from '../logger/arms-config';

const allowedHosts = [
  'ielts.dauyan.net',
  'ielts.dauyan.com',
  'share.dauyan.net',
  'share.dauyan.com',
  'app.dauyan.net',
  'app.dauyan.com',
  'test.dauyan.net',
  '172.16.80.171',
  '192.168.1.48',
  'www.donglyc.com',
  ieltsHost,
];

export function checkHost() {
  if (!allowedHosts.includes(window.location.hostname)) {
    logError(`[非法域名]${window.location.href}`);
    setTimeout(() => {
      window.location.href = ieltsWeb;
    }, 100);
  }
}
