import axios, { AxiosResponse } from 'axios';

import jsCookie from 'js-cookie';
import { get } from 'lodash-es';
import toast from 'react-hot-toast';
import i18n from '@/locales/init';
import { PermisssionModal } from '@/modules/Permissions/components/PermissionModal';
import { LoginModal } from '@/modules/Permissions/components/LogInModal';
import { setTokenInvalid, login, clearLoginState } from '@/modules/Permissions';
import { ieltsHost } from '../env';

// const API_WHITE_LIST = ['/api/student/get-operation-permissions-list'];
const API_WHITE_LIST = ['/api/student/get-operation-permissions-list', '/api/student/get-equity-remain-info'];

const checkIsTourist = (data: any) => {
  return get(data, 'header[\'x-user-role\']') === 'tourist';
};

axios.interceptors.request.use(
  function (config) {
    const token = jsCookie.get('token');
    config.headers.Authorization = config?.headers?.Authorization ?? (token || '');
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// TODO: 不跳转的情况随后添加接口白名单

function notAuthHandler() {
  setTokenInvalid();
  login();
}


// 拦截器1: 重新组织数据结构，非 200 状态码走后续异常逻辑
axios.interceptors.response.use(
  (response: AxiosResponse) => {
    const { code, message: errorMessage, statusCode, } = response.data;
    if (code === 200 || statusCode === 200) {
      return {
        ...response.data,
        header: response.headers
      };
    }
    if (code !== 200) {
      return Promise.reject({ ...response.data, error: errorMessage, header: response.headers, config: response.config });
    }
  },
  function (error) {
    if (axios.isCancel(error)) {
      return Promise.resolve(error);
    } else {
      return Promise.reject(error);
    }
  }
);

// 拦截器2: 权限拦截
axios.interceptors.response.use(
  function (response: AxiosResponse) {
    return response;
  },
  function (error) {
    const { code } = error as any;

    switch (code) {
      case 401: {
        // 未登录
        notAuthHandler();
        break;
      }
      case 3001: {
        // 用户未登录且不是游客(无token)
        if (!API_WHITE_LIST.includes(error.config.url)) {
          if (!checkIsTourist(error)) {
            PermisssionModal.open();
          } else {
            LoginModal.open();
          }
        }
        break;
      }
      case 3005: {
        toast.error(i18n.t('common.dauyan_network_times_limit'));
        break;
      }
      case 3020: {
        toast.error(i18n.t('common.dauyan_user_deleted'));
        clearLoginState();
        LoginModal.open(() => {
          window.location.href = ieltsHost;
        });
        break;
      }
      default: break;
    }

    return Promise.reject(error);
  }
);