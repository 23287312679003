import Axios from 'axios';
import { AxiosResponseType } from '@/utils/network/axios-helper';

/**
 * 保存笔记
 * @param params 
 * @returns 
 */
export const saveExam = async (params: any): Promise<AxiosResponseType<any>> => {
  return Axios.post('/api/exam/save', params);
};

export const getExam = async (params: any): Promise<AxiosResponseType<any>> => {
  return Axios.post('/api/exam/get-list', params);
};

export const getMenuList = async (): Promise<AxiosResponseType<any>> => {
  return Axios.post('/api/system/get-pc-menu-list', {});
};

export const get_selected_list = async (): Promise<AxiosResponseType<any>> => {
  return Axios.post('/api/student/get-dropdown-list', {});
};